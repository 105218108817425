import { useState, useEffect, useRef } from "react";
import { DomEvent } from "leaflet";
import { Link } from "react-router-dom";
import CloseButton from "../Buttons/CloseButton";
import ArmyForm from "./Forms/ArmyForm";
import classes from "./ArmiesBox.module.scss";

const ArmiesBox = ({ armies, filteredArmies, setFilteredArmies, nodes, onClose, setCenter, disabled }) => {
  const groupArmiesByFaction = (armies) => {
    return Object.entries(
      armies.reduce((acc, army) => {
        if (!acc[army.faction]) {
          acc[army.faction] = [];
        }
        acc[army.faction].push(army);
        return acc;
      }, {})
    ).sort(([factionA], [factionB]) => {
      if (factionA.startsWith('bl')) return -1;
      if (factionB.startsWith('bl')) return 1;
      return 0;
    })
      .filter(([factionName]) => factionName !== 'scout');
  }

  const [creatingArmy, setCreatingArmy] = useState(false);
  const [filteredArmiesByFaction, setFilteredArmiesByFaction] = useState(groupArmiesByFaction(filteredArmies));

  const armiesByFaction = groupArmiesByFaction(armies);

  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current) {
      DomEvent.disableScrollPropagation(containerRef.current);
    }
  }, [containerRef]);

  const handleClick = (army) => {
    const armyPosition = nodes.find((node) => node.id === army.node).position;
    setCenter(armyPosition);
  };

  const handleFilterClick = (faction, factionArmies) => {
    if (filteredArmiesByFaction.some(([factionName]) => factionName === faction)) {
      const updatedFilteredArmiesByFaction = filteredArmiesByFaction.filter(([factionName]) => factionName !== faction);
      setFilteredArmiesByFaction(updatedFilteredArmiesByFaction);
      const updatedFilteredArmies = filteredArmies.filter((army) => army.faction !== faction);
      setFilteredArmies(updatedFilteredArmies);
    } else {
      const updatedFilteredArmiesByFaction = [...filteredArmiesByFaction, [faction, factionArmies]];
      setFilteredArmiesByFaction(updatedFilteredArmiesByFaction);
      const armiesToAdd = armies.filter((army) => army.faction === faction);
      setFilteredArmies([...filteredArmies, ...armiesToAdd]);
    }
  };

  if (creatingArmy) {
    return <ArmyForm nodes={nodes} onClose={onClose} />;
  }

  return (
    <div className={classes.box} ref={containerRef}>
      <CloseButton onClose={onClose} />
      {!disabled &&
        <div onClick={() => setCreatingArmy(true)} className={classes.btn}>
          Add new army
        </div>}
      <div className={classes.filtersBox}>
        {armiesByFaction.map(([faction, factionArmies], index) => {
          const factionExists = filteredArmiesByFaction.some(([factionName]) => factionName === faction);
          const imageStyle = {
            opacity: factionExists ? 1 : 0.3,
          };
          return (
            <div key={index} style={{ display: 'inline-block', marginRight: '10px' }}>
              <div
                onClick={() => handleFilterClick(faction, factionArmies)}
                style={{ cursor: 'pointer' }}
                className={classes.customTooltipContainer}
              >
                <span className={classes.customTooltipText}>{faction}</span>
                <img
                  src={`Images/flags/${faction}.png`}
                  alt=""
                  className={classes.flagFilter}
                  style={imageStyle}
                />
              </div>
            </div>
          );
        })}
      </div>

      <h5>Armies:</h5>

      {/* Group by factions and render */}
      {filteredArmiesByFaction.map(([faction, factionArmies], index) => (
        <div key={`wrapper-${faction}`}>
          {index !== 0 && <div className={classes.horizontalLine}></div>}
          <div key={faction}>
            <img
              src={`Images/flags/${faction}.png`}
              className={`${classes.flagInList}`}
            />
            <ul>
              {factionArmies.map(army => (
                <li key={`army-${army.id}`}>
                  <Link onClick={() => handleClick(army)}>
                    {army.name} - node {army.node}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ArmiesBox;
