export const nodes = [
  { id: 2721, position: [15.82, -10.77], type: "port", name: "Anhialo" },
  { id: 2720, position: [16.02, -12.58], type: "node", name: "" },
  { id: 2719, position: [16.62, -15.16], type: "node", name: "" },
  { id: 1355, position: [17.82, -17.57], type: "mine", name: "Gold Mine" },
  {
    id: 1354,
    position: [17.53, -18.85],
    type: "city",
    name: "Veliko Tarnovo",
    walls: "80%",
  },
  { id: 1353, position: [17.67, -19.74], type: "node", name: "" },
  { id: 665, position: [18.33, -21.3], type: "city", name: "Lovech" },
  { id: 975, position: [19.89, -21.27], type: "node", name: "" },
  { id: 976, position: [21.08, -21.25], type: "port", name: "" },
  { id: 977, position: [22.55, -20.54], type: "node", name: "" },
  {
    id: 687,
    position: [24.15, -20.24],
    type: "city",
    name: "Roşiorii de Vede",
  },
  { id: 688, position: [24.87, -21.42], type: "mine", name: "Gold Mine" },
  { id: 816, position: [25.72, -22.22], type: "city", name: "Slatina" },
  { id: 872, position: [26.56, -21.28], type: "mine", name: "Iron Mine" },
  { id: 873, position: [27.26, -21.14], type: "city", name: "Piteşti" },
  { id: 874, position: [28.24, -21.51], type: "mine", name: "Stone Mine" },
  { id: 875, position: [29.58, -21.24], type: "city", name: "Curtea de Argeş" },
  { id: 1038, position: [28.93, -20.36], type: "mine", name: "Iron Mine" },
  { id: 1036, position: [31.04, -21.48], type: "mine", name: "Gold Mine" },
  { id: 1037, position: [31.84, -21.26], type: "city", name: "Câmpulung" },
  { id: 2781, position: [34.19, -24], type: "node", name: "" },
  { id: 2782, position: [35.62, -26.48], type: "node", name: "" },
  { id: 2783, position: [37.56, -27.6], type: "node", name: "" },
  { id: 2784, position: [38.94, -30.98], type: "node", name: "" },
  { id: 2785, position: [40.33, -33.74], type: "node", name: "" },
  { id: 2786, position: [42.4, -35.37], type: "node", name: "" },
  { id: 2787, position: [43.57, -37.1], type: "node", name: "" },
  { id: 2538, position: [44.91, -38], type: "node", name: "" },
  { id: 2741, position: [44.9, -39.79], type: "node", name: "" },
  { id: 2740, position: [44.25, -41.57], type: "node", name: "" },
  { id: 2739, position: [43.77, -43.23], type: "node", name: "" },
  { id: 2738, position: [42.46, -45.1], type: "node", name: "" },
  { id: 2537, position: [46.03, -38.07], type: "node", name: "" },
  { id: 2536, position: [47.22, -38.78], type: "node", name: "" },
  { id: 2535, position: [47.83, -39.88], type: "node", name: "" },
  { id: 2534, position: [48.55, -38.97], type: "node", name: "" },
  { id: 2533, position: [49.2, -40.55], type: "node", name: "" },
  { id: 2532, position: [50.13, -41.65], type: "node", name: "" },
  { id: 1033, position: [50.84, -42.1], type: "city", name: "Nowy Targ" },
  { id: 1032, position: [50.81, -40.5], type: "mine", name: "Salt Mine" },
  { id: 1031, position: [51.4, -38.7], type: "city", name: "Nowy Sącz" },
  { id: 1030, position: [51.94, -37.95], type: "mine", name: "Gold Mine" },
  { id: 1029, position: [52.9, -37], type: "city", name: "Tarnow" },
  { id: 1035, position: [52.98, -38.87], type: "mine", name: "Salt Mine" },
  { id: 1028, position: [52.93, -40.58], type: "node", name: "" },
  { id: 1027, position: [52.92, -42.4], type: "mine", name: "Clay Mine" },
  { id: 1024, position: [53.5, -44.1], type: "city", name: "Krakow" },
  { id: 2742, position: [51.88, -43.3], type: "node", name: "" },
  { id: 1023, position: [54.08, -44.98], type: "node", name: "" },
  { id: 1022, position: [54.5, -45.9], type: "city", name: "Wolbrom" },
  { id: 1021, position: [55.05, -46.98], type: "node", name: "" },
  { id: 568, position: [56, -48.6], type: "city", name: "Częstochowa" },
  { id: 579, position: [56.97, -50.29], type: "mine", name: "Iron Mine" },
  { id: 677, position: [58.4, -50.46], type: "mine", name: "Stone Mine" },
  { id: 625, position: [59.33, -51.2], type: "city", name: "Kalisz" },
  { id: 678, position: [59.85, -52.93], type: "mine", name: "Clay Mine" },
  { id: 679, position: [60.64, -54.63], type: "node", name: "" },
  { id: 2821, position: [60.99, -56.5], type: "mine", name: "Gold Mine" },
  { id: 680, position: [61.46, -53.82], type: "node", name: "" },
  { id: 681, position: [62.3, -53.6], type: "city", name: "Gniezno" },
  { id: 682, position: [62.4, -56.45], type: "mine", name: "Iron Mine" },
  { id: 683, position: [62.33, -58.1], type: "city", name: "Poznań" },
  { id: 714, position: [62.41, -60.94], type: "node", name: "Stone Mine" },
  { id: 716, position: [62.7, -63.5], type: "city", name: "Skwierzyna" },
  { id: 2733, position: [55.05, -44.96], type: "node", name: "" },
  { id: 2734, position: [55.5, -43.2], type: "node", name: "" },
  { id: 2735, position: [56.4, -42.35], type: "node", name: "" },
  { id: 2736, position: [57.34, -41.45], type: "node", name: "" },
  { id: 2737, position: [58.38, -41.52], type: "node", name: "" },
  { id: 737, position: [59.27, -40.67], type: "city", name: "Rawa" },
  { id: 738, position: [59.63, -43.16], type: "node", name: "" },
  { id: 739, position: [60.14, -45.02], type: "node", name: "" },
  { id: 724, position: [60.58, -47.57], type: "node", name: "" },
  { id: 725, position: [61.49, -45.86], type: "node", name: "" },
  { id: 726, position: [61.98, -44.85], type: "node", name: "" },
  { id: 727, position: [62.63, -43.2], type: "city", name: "Płock" },
  { id: 728, position: [62.39, -41.6], type: "mine", name: "Stone Mine" },
  { id: 729, position: [62.32, -40.55], type: "city", name: "Wyszogród" },
  { id: 730, position: [61.83, -39.65], type: "node", name: "" },
  { id: 731, position: [61.5, -38.8], type: "city", name: "Warszawa" },
  { id: 732, position: [60.73, -37.4], type: "mine", name: "Gold Mine" },
  { id: 733, position: [60.2, -36.05], type: "city", name: "Czersk" },
  { id: 735, position: [59.25, -38.02], type: "mine", name: "Clay Mine" },
  { id: 1025, position: [53, -45.7], type: "mine", name: "Iron Mine" },
  { id: 1026, position: [52.8, -46.65], type: "city", name: "Oświęcim" },
  { id: 1282, position: [52.42, -47.47], type: "node", name: "" },
  { id: 1281, position: [51.97, -47.74], type: "node", name: "" },
  { id: 1280, position: [51.85, -49.5], type: "node", name: "" },
  { id: 1279, position: [51.8, -51.1], type: "node", name: "" },
  { id: 1278, position: [51.48, -52.12], type: "node", name: "" },
  { id: 1277, position: [51.35, -53.89], type: "node", name: "" },
  { id: 1276, position: [50.9, -54.78], type: "node", name: "" },
  { id: 1275, position: [50.26, -55.65], type: "mine", name: "" },
  { id: 1274, position: [49.7, -57.24], type: "city", name: "Brno" },
  { id: 1273, position: [49.02, -57.26], type: "mine", name: "" },
  { id: 1272, position: [48.03, -57.41], type: "", name: "" },
  { id: 981, position: [47.39, -58.2], type: "city", name: "Mistelbach" },
  { id: 980, position: [46.82, -58.92], type: "node", name: "" },


  { id: 1040, position: [27.23, -19.47], type: "mine", name: "Clay Mine" },
  { id: 1039, position: [28.02, -18.66], type: "city", name: "Târgovişte" },
  { id: 1041, position: [28.79, -17.69], type: "node", name: "" },
  { id: 1042, position: [28.82, -16.04], type: "city", name: "Târgşor" },
  { id: 1043, position: [28.05, -15.12], type: "mine", name: "Stone Mine" },
  { id: 1044, position: [27.33, -14.28], type: "city", name: "Snagov" },
  { id: 2674, position: [28.09, -11.61], type: "node", name: "" },
  { id: 2675, position: [28.05, -9.07], type: "node", name: "" },
  { id: 2676, position: [28.21, -6.54], type: "node", name: "" },
  { id: 1763, position: [28.64, -4.04], type: "port", name: "" },
  { id: 2677, position: [30.26, -3.58], type: "node", name: "" },
  { id: 2678, position: [32.48, -3.56], type: "node", name: "" },
  { id: 2679, position: [34.13, -2.11], type: "node", name: "" },
  { id: 2680, position: [36.05, 0.55], type: "node", name: "" },
  { id: 2681, position: [37.07, 2.31], type: "node", name: "" },
  { id: 2682, position: [38.28, 3.26], type: "node", name: "" },
  { id: 2683, position: [38.3, 6.8], type: "node", name: "Great Khan's camp" },
  { id: 2748, position: [24.88, -24.04], type: "node", name: "" },
  { id: 2747, position: [24.84, -25.69], type: "node", name: "" },
  { id: 2746, position: [24.25, -27.48], type: "node", name: "" },
  { id: 2745, position: [24.01, -29.11], type: "node", name: "" },
  { id: 664, position: [18.46, -22.31], type: "mine", name: "Stone Mine" },
  { id: 663, position: [18.46, -23.92], type: "node", name: "" },
  { id: 662, position: [18.63, -25.73], type: "mine", name: "Clay Mine" },
  { id: 575, position: [19.28, -27.2], type: "city", name: "Vratsa" },
  { id: 574, position: [19.41, -28.36], type: "mine", name: "Gold Mine" },
  { id: 573, position: [20.2, -29.25], type: "city", name: "Kutlovitsa" },
  { id: 572, position: [20.87, -29.27], type: "mine", name: "Stone Mine" },
  { id: 571, position: [21.8, -29.4], type: "city", name: "Lom" },
  { id: 570, position: [22.59, -30.16], type: "mine", name: "Iron Mine" },
  { id: 547, position: [23.33, -31.1], type: "city", name: "Vidin" },
  { id: 548, position: [24.14, -31.87], type: "mine", name: "Gold Mine" },
  { id: 1306, position: [25.56, -32.67], type: "node", name: "" },
  { id: 1305, position: [25.86, -33.77], type: "node", name: "" },
  { id: 1304, position: [25.96, -35.17], type: "node", name: "" },
  { id: 1303, position: [26.7, -36.1], type: "node", name: "" },
  { id: 1302, position: [27.2, -37.2], type: "mine", name: "Clay Mine" },
  { id: 1216, position: [27.26, -38.86], type: "city", name: "Смедерево" },
  { id: 1301, position: [27.16, -39.8], type: "node", name: "" },
  { id: 1300, position: [27.335, -40.655], type: "node", name: "" },
  { id: 1388, position: [27.18, -41.68], type: "node", name: "" },
  { id: 1387, position: [27.45, -43.12], type: "node", name: "" },
  { id: 1386, position: [28.24, -44.21], type: "node", name: "" },
  { id: 1385, position: [27.435, -45.96], type: "node", name: "" },
  { id: 1384, position: [28.03, -47.545], type: "node", name: "" },
  { id: 1383, position: [28.26, -48.58], type: "node", name: "" },
  { id: 1382, position: [28.04, -50.115], type: "node", name: "" },
  { id: 1381, position: [28.33, -51.39], type: "node", name: "" },
  { id: 1380, position: [28.79, -52.66], type: "node", name: "" },
  { id: 1379, position: [29.13, -53.74], type: "node", name: "" },
  { id: 1378, position: [29.55, -55.46], type: "node", name: "" },
  { id: 1377, position: [29.66, -56.38], type: "node", name: "" },
  { id: 1376, position: [30.3, -57.58], type: "node", name: "" },
  { id: 1375, position: [31.05, -59], type: "node", name: "" },
  { id: 1374, position: [32.02, -59.9], type: "node", name: "" },
  { id: 1269, position: [32.46, -60.92], type: "node", name: "" },
  { id: 1270, position: [31.83, -61.7], type: "mine", name: "Clay Mine" },
  { id: 1362, position: [31.4, -62.6], type: "city", name: "Dubovac" },
  { id: 1271, position: [31.09, -63.48], type: "mine", name: "Stone Mine" },
  { id: 817, position: [30.35, -65.1], type: "city", name: "Vrbovsko" },
  { id: 818, position: [30.56, -67.27], type: "mine", name: "Gold Mine" },
  { id: 1020, position: [30.38, -68.34], type: "city", name: "Rijeka" },
  { id: 2774, position: [28.76, -70.45], type: "node", name: "" },
  { id: 1350, position: [27.4, -70.95], type: "city", name: "Pola" },
  { id: 1349, position: [28.3, -71.95], type: "mine", name: "Gold Mine" },
  { id: 1348, position: [29.6, -72], type: "city", name: "Parenzo" },
  { id: 1347, position: [30.96, -72.14], type: "mine", name: "Clay Mine" },
  { id: 1346, position: [31.28, -71.3], type: "", name: "" },
  { id: 1351, position: [31.04, -70.45], type: "node", name: "" },
  { id: 1352, position: [31.22, -69.53], type: "mine", name: "Iron Mine" },
  { id: 1345, position: [32.05, -72.33], type: "mine", name: "Salt Mine" },
  { id: 1343, position: [33.45, -73], type: "city", name: "Udine" },
  { id: 2286, position: [33.97, -72.25], type: "node", name: "" },
  { id: 2287, position: [34.05, -71.24], type: "node", name: "" },
  { id: 2288, position: [34.68, -70.63], type: "node", name: "" },
  { id: 2289, position: [34.96, -69.72], type: "node", name: "" },
  { id: 2290, position: [34.75, -68.57], type: "node", name: "" },
  { id: 2291, position: [35, -67.62], type: "node", name: "" },
  { id: 2151, position: [35.55, -66.13], type: "city", name: "Celje" },
  { id: 2284, position: [36.1, -65.2], type: "node", name: "" },
  { id: 2285, position: [36.4, -63.35], type: "node", name: "" },
  {
    id: 1186,
    position: [36.9, -62.55],
    type: "city",
    name: "Marburg an der Drau",
  },
  { id: 1185, position: [38.3, -63.43], type: "mine", name: "Stone Mine" },
  { id: 1183, position: [39.6, -63.6], type: "city", name: "Graz" },
  { id: 1182, position: [40.37, -64.38], type: "mine", name: "Iron Mine" },
  {
    id: 1181,
    position: [41.14, -64.24],
    type: "city",
    name: "Bruck an der Mur",
  },
  { id: 1180, position: [41.6, -63.29], type: "mine", name: "Stone Mine" },
  { id: 1179, position: [42.25, -62.6], type: "node", name: "" },
  { id: 1178, position: [42.36, -61.64], type: "mine", name: "Iron Mine" },
  { id: 983, position: [43.02, -60], type: "city", name: "Ternitz" },
  { id: 982, position: [44.3, -59.9], type: "mine", name: "Clay Mine" },
  { id: 698, position: [45.6, -59], type: "city", name: "Wien" },
  { id: 1283, position: [45, -57.52], type: "node", name: "" },
  { id: 1284, position: [45.1, -55.48], type: "node", name: "" },
  { id: 1285, position: [44.18, -55], type: "node", name: "" },
  { id: 1286, position: [43.5, -54.72], type: "node", name: "" },
  { id: 877, position: [43.125, -54.15], type: "city", name: "Gyor" },
  { id: 878, position: [42.9, -52.85], type: "mine", name: "Salt Mine?" },
  { id: 1017, position: [43.08, -52], type: "node", name: "" },
  { id: 1018, position: [43.08, -50.3], type: "city", name: "Esztergom" },
  { id: 1019, position: [43.2, -49.2], type: "mine", name: "?" },
  { id: 1287, position: [42.34, -47.65], type: "node", name: "" },
  { id: 1288, position: [41.08, -47.82], type: "node", name: "" },
  { id: 1289, position: [39.8, -47.8], type: "node", name: "" },
  { id: 1290, position: [38.32, -47.78], type: "node", name: "" },
  { id: 1291, position: [37, -47.88], type: "node", name: "" },
  { id: 1292, position: [35.4, -48.18], type: "node", name: "" },
  { id: 1293, position: [34.1, -48.72], type: "node", name: "" },
  { id: 1294, position: [32.75, -47.73], type: "node", name: "" },
  { id: 1295, position: [31.85, -47.5], type: "node", name: "" },
  { id: 1296, position: [30.66, -45.88], type: "node", name: "" },
  { id: 1297, position: [30.27, -44.13], type: "node", name: "" },
  { id: 1298, position: [29.8, -42.5], type: "node", name: "" },
  { id: 1299, position: [28.55, -41.7], type: "node", name: "" },
  { id: 1363, position: [41.64, -49.25], type: "mine", name: "?" },
  { id: 1364, position: [41.1, -50.45], type: "city", name: "Székesfehérvár" },
  { id: 1365, position: [39.8, -51.15], type: "mine", name: "?" },
  { id: 1366, position: [39, -52.05], type: "node", name: "" },
  { id: 1367, position: [38.3, -53.7], type: "node", name: "" },
  { id: 1368, position: [37.6, -55.56], type: "node", name: "" },
  { id: 1369, position: [37.48, -57.15], type: "node", name: "" },
  { id: 1370, position: [36.4, -58.29], type: "node", name: "" },
  { id: 1371, position: [34.8, -59.07], type: "node", name: "" },
  { id: 1372, position: [34.25, -59.82], type: "node", name: "" },
  { id: 1373, position: [33.37, -60.1], type: "node", name: "" },
  { id: 2152, position: [34.92, -65.27], type: "mine", name: "Gold Mine" },
  { id: 2280, position: [34.6, -64.2], type: "node", name: "" },
  { id: 2281, position: [34.2, -63.3], type: "node", name: "" },
  { id: 2282, position: [34.08, -62.35], type: "node", name: "" },
  { id: 1268, position: [33.48, -61.63], type: "node", name: "" },
  { id: 1267, position: [34.7, -60.9], type: "node", name: "" },
  { id: 1266, position: [35.6, -60.78], type: "node", name: "" },
  { id: 1265, position: [36.15, -61.83], type: "node", name: "" },
  { id: 2776, position: [33.5, -66.84], type: "node", name: "" },
  { id: 2775, position: [31.93, -68], type: "node", name: "" },
  { id: 1342, position: [32.67, -74.05], type: "mine", name: "Iron Mine" },
  { id: 1340, position: [32.4, -75.5], type: "city", name: "Portogruaro" },
  { id: 1339, position: [32.6, -76.55], type: "mine", name: "Clay Mine" },
  { id: 1337, position: [31.85, -77.8], type: "city", name: "Treviso" },
  { id: 1336, position: [31.18, -77.55], type: "mine", name: "Stone Mine" },
  { id: 1335, position: [30.4, -77.5], type: "city", name: "Venezia" },
  { id: 1344, position: [34.05, -73.1], type: "mine", name: "Gold Mine" },
  { id: 1389, position: [27.17, -51.06], type: "node", name: "" },
  { id: 1390, position: [26.35, -51.96], type: "node", name: "" },
  { id: 1391, position: [25.57, -52.76], type: "node", name: "" },
  { id: 1392, position: [24.14, -52.18], type: "mine", name: "Clay Mine" },
  { id: 2329, position: [25.68, -39.12], type: "mine", name: "Iron Mine" },
  { id: 2328, position: [24.18, -39.68], type: "city", name: "Рудник" },
  { id: 2531, position: [23.13, -39.92], type: "node", name: "" },
  { id: 2530, position: [22.5, -41.52], type: "node", name: "" },
  { id: 2529, position: [22.35, -43.22], type: "node", name: "" },
  { id: 2528, position: [22.42, -44.77], type: "node", name: "" },
  { id: 2527, position: [21.58, -46.04], type: "node", name: "" },
  { id: 2526, position: [20.66, -47.65], type: "node", name: "" },
  { id: 2525, position: [20.96, -49.34], type: "node", name: "" },
  { id: 2326, position: [23.38, -37.95], type: "mine", name: "Gold Mine" },
  { id: 1404, position: [23.28, -36.2], type: "city", name: "Јагодна" },
  { id: 2743, position: [23.24, -34.51], type: "node", name: "" },
  { id: 2744, position: [23.44, -32.79], type: "node", name: "" },
  { id: 1217, position: [26.74, -37.96], type: "mine", name: "Iron Mine" },
  { id: 1403, position: [24.92, -37.06], type: "node", name: "" },
  { id: 653, position: [18.4, -27.44], type: "node", name: "" },
  { id: 654, position: [17.75, -28.55], type: "node", name: "" },
  { id: 655, position: [16.74, -29.25], type: "city", name: "Sofia" },
  { id: 656, position: [15.82, -28.36], type: "mine", name: "Iron Mine" },
  { id: 657, position: [15.19, -27.4], type: "city", name: "Samokov" },
  { id: 2716, position: [12.49, -27.49], type: "node", name: "" },
  { id: 2717, position: [10.65, -27.38], type: "node", name: "" },
  { id: 2718, position: [8.95, -27.54], type: "node", name: "" },
  { id: 666, position: [7.28, -27.5], type: "city", name: "Σέρρες" },
  { id: 667, position: [6.6, -29.43], type: "mine", name: "Gold Mine" },
  { id: 876, position: [6.4, -30.73], type: "city", name: "Κιλκίς" },
  { id: 1402, position: [12.71, -45.68], type: "mine", name: "Clay Mine" },
  { id: 1401, position: [13.98, -46.035], type: "node", name: "" },
  { id: 1400, position: [14.96, -46.87], type: "node", name: "" },
  { id: 1399, position: [16, -47.52], type: "node", name: "" },
  { id: 1398, position: [17.45, -48.31], type: "node", name: "" },
  { id: 1397, position: [18.26, -49.49], type: "node", name: "" },
  { id: 1396, position: [19.41, -50.11], type: "node", name: "" },
  { id: 1395, position: [20.81, -51.23], type: "node", name: "" },
  { id: 1394, position: [21.83, -51.865], type: "mine", name: "Gold Mine" },
  { id: 1393, position: [22.37, -52.94], type: "city", name: "Zenica" },
  { id: 2522, position: [21.48, -54.78], type: "node", name: "" },
  { id: 2523, position: [20.22, -55.48], type: "mine", name: "Iron Mine" },
  { id: 2524, position: [19.25, -56.5], type: "city", name: "Brštanik" },
  { id: 2672, position: [18.28, -57.96], type: "mine", name: "Stone Mine" },
  { id: 1314, position: [11.64, -44.92], type: "city", name: "Lezhë" },
  { id: 1315, position: [11.44, -43.36], type: "mine", name: "Gold Mide" },
  { id: 2521, position: [10.92, -42.59], type: "node", name: "" },
  { id: 2520, position: [10.06, -41.69], type: "node", name: "" },
  { id: 2519, position: [9.77, -40.51], type: "node", name: "" },
  { id: 2518, position: [8.95, -39.68], type: "node", name: "" },
  { id: 2517, position: [9.14, -38.24], type: "node", name: "" },
  { id: 2516, position: [8.16, -37.13], type: "node", name: "" },
  { id: 2515, position: [8.05, -35.49], type: "node", name: "" },
  { id: 2514, position: [7.37, -34.37], type: "node", name: "" },
  { id: 2513, position: [6.62, -33.5], type: "node", name: "" },
  { id: 2512, position: [6.27, -31.96], type: "node", name: "" },
  { id: 2511, position: [5.37, -31.95], type: "node", name: "" },
  { id: 2510, position: [4.73, -33.74], type: "node", name: "" },
  { id: 2509, position: [4.75, -35.56], type: "node", name: "" },
  { id: 2508, position: [5.565, -37.24], type: "node", name: "" },
  { id: 2507, position: [4.59, -39.05], type: "node", name: "" },
  { id: 2506, position: [5.63, -40.6], type: "node", name: "" },
  { id: 2505, position: [5.69, -42.2], type: "node", name: "" },
  { id: 2504, position: [4.6, -43.32], type: "node", name: "" },
  { id: 2503, position: [5.45, -45.03], type: "mine", name: "Iron Mine" },
  { id: 2502, position: [5.55, -46.63], type: "city", name: "Dyrrah" },
  { id: 2501, position: [8, -46.48], type: "mine", name: "Stone Mine" },
  { id: 2500, position: [9.72, -45.12], type: "node", name: "" },
  { id: 1015, position: [6.32, -27.42], type: "mine", name: "Iron Mine" },
  { id: 1016, position: [5.42, -27.62], type: "city", name: "Άγιος Γεώργιος" },
  { id: 1308, position: [8.02, -24.93], type: "mine", name: "Clay Mine" },
  { id: 1310, position: [7, -23.07], type: "node", name: "" },
  { id: 1312, position: [7.28, -20.43], type: "node", name: "" },
  { id: 860, position: [6.22, -17.89], type: "node", name: "" },
  { id: 859, position: [7.09, -16.17], type: "mine", name: "Stone Mine" },
  { id: 658, position: [14.2, -26.46], type: "mine", name: "Iron Mine" },
  { id: 659, position: [13.45, -24.82], type: "node", name: "" },
  { id: 660, position: [13.52, -23.82], type: "node", name: "" },
  { id: 661, position: [12.35, -22.24], type: "node", name: "" },
  { id: 970, position: [12.67, -20.46], type: "node", name: "" },
  { id: 971, position: [11.44, -19.54], type: "node", name: "" },
  { id: 972, position: [11.93, -18.6], type: "node", name: "" },
  { id: 973, position: [11.6, -17.8], type: "city", name: "Harmanli" },
  { id: 974, position: [10.6, -16.92], type: "mine", name: "Stone Mine" },
  { id: 824, position: [10.8, -15.1], type: "city", name: "Edirne" },
  { id: 825, position: [9.225, -14.45], type: "mine", name: "Clay Mine" },
  { id: 795, position: [7.3, -14.37], type: "city", name: "Keşan" },
  { id: 794, position: [6.635, -13.25], type: "mine", name: "Stone Mine" },
  { id: 793, position: [6.335, -11.7], type: "city", name: "Şarköy" },
  { id: 720, position: [5.81, -12.6], type: "mine", name: "Gold Mine" },
  { id: 719, position: [5.45, -13.4], type: "city", name: "Gelibolu" },
  { id: 950, position: [4.5, -13.6], type: "node", name: "" },
  { id: 951, position: [3.6, -15.05], type: "city", name: "Çanakkale" },
  { id: 952, position: [2.8, -15.12], type: "mine", name: "Gold Mine" },
  { id: 953, position: [2, -15.12], type: "city", name: "Ezine" },
  { id: 954, position: [1.15, -15.14], type: "mine", name: "Stone Mine" },
  { id: 1218, position: [0.42, -14.23], type: "mine", name: "" },
  { id: 955, position: [0.3, -12.38], type: "city", name: "Edremit" },
  { id: 958, position: [0.14, -11.39], type: "mine", name: "Iron Mine" },
  { id: 959, position: [0.3, -10.7], type: "city", name: "Balya" },
  { id: 960, position: [0.11, -9.93], type: "node", name: "" },
  { id: 961, position: [0.4, -9], type: "city", name: "Balikesir" },
  { id: 967, position: [0.23, -8.12], type: "node", name: "" },
  { id: 968, position: [0.55, -7.12], type: "mine", name: "Gold Mine" },
  { id: 969, position: [0.28, -6.36], type: "city", name: "Dursunbey" },
  { id: 1223, position: [-0.5, -5.26], type: "mine", name: "Stone Mine" },
  { id: 1224, position: [-0.7, -3.74], type: "city", name: "Tavşanlı" },
  { id: 1242, position: [0.24, -3.76], type: "node", node: "" },
  { id: 1243, position: [1.02, -2.92], type: "node", node: "" },
  { id: 1244, position: [1.94, -2.82], type: "city", name: "Inegöl" },
  { id: 1245, position: [2.1, -3.8], type: "mine", name: "Iron Mine" },
  { id: 1247, position: [2.92, -4.48], type: "node", name: "" },
  { id: 1246, position: [3.12, -5.55], type: "node", name: "" },
  { id: 1221, position: [2.8, -7.05], type: "city", name: "Karacabey" },
  { id: 1222, position: [3, -8.21], type: "node", name: "" },
  { id: 966, position: [3.62, -8.94], type: "city", name: "Bandirma" },
  { id: 965, position: [3.17, -9.96], type: "mine", name: "Iron Mine" },
  { id: 964, position: [2.9, -10.75], type: "city", name: "Gönen" },
  { id: 1241, position: [2.73, -11.59], type: "mine", name: "Stone Mine" },
  { id: 1240, position: [2.89, -12.45], type: "city", name: "Çan" },
  { id: 1239, position: [3.18, -14.12], type: "mine", name: "Gold Mine" },
  { id: 1248, position: [3.845, -3.72], type: "mine", name: "Iron Mine" },
  { id: 1249, position: [4.64, -3.72], type: "city", name: "Gemlik" },
  { id: 1264, position: [-0.7, -8.92], type: "mine", name: "" },
  { id: 1219, position: [1.24, -8.21], type: "node", name: "" },
  { id: 1220, position: [2.02, -7.94], type: "node", name: "" },
  { id: 962, position: [1.32, -10], type: "node", name: "" },
  { id: 963, position: [2.19, -10.8], type: "node", name: "" },
  { id: 956, position: [-0.44, -13.56], type: "mine", name: "Stone Mine" },
  { id: 957, position: [-1.56, -14.14], type: "city", name: "Ayvalik" },
  { id: 823, position: [10.78, -13.33], type: "mine", name: "Iron Mine" },
  { id: 822, position: [10.43, -11.63], type: "city", name: "Kirklareli" },
  { id: 821, position: [8.9, -11.4], type: "mine", name: "Iron Mine" },
  { id: 820, position: [7.65, -10.8], type: "city", name: "Tekirdağ" },
  { id: 819, position: [7.31, -11.54], type: "node", name: "" },
  { id: 861, position: [8.1, -9.97], type: "mine", name: "Gold Mine" },
  { id: 862, position: [9, -9.85], type: "node", name: "Türbedere" },
  { id: 2810, position: [15.87, -19.61], type: "node", name: "" },
  { id: 2811, position: [14.24, -18.71], type: "node", name: "" },
  { id: 2812, position: [13.39, -16.97], type: "node", name: "" },

  // Italy
  { id: 1153, position: [8.2, -68.65], type: "city", name: "Capua" },
  { id: 1152, position: [8.9, -69.64], type: "mine", name: "Iron Mine" },
  { id: 1151, position: [9.3, -70.6], type: "city", name: "Sessa Aurunca" },
  { id: 1150, position: [8.93, -71.32], type: "node", name: "" },
  { id: 1145, position: [9.2, -72.2], type: "city", name: "Gaeta" },
  { id: 1144, position: [8.91, -73.1], type: "mine", name: "Stone Mine" },
  { id: 1143, position: [9, -74.1], type: "city", name: "Terracina" },
  { id: 1146, position: [9.86, -71.31], type: "mine", name: "Clay Mine" },
  { id: 1148, position: [10.75, -71.41], type: "mine", name: "Gold Mine" },
  { id: 1147, position: [10.85, -70.35], type: "city", name: "Pontecorvo" },
  { id: 1149, position: [11.7, -72.3], type: "city", name: "Sora" },
  { id: 1193, position: [12.45, -73.15], type: "node", name: "" },
  { id: 1194, position: [13.3, -73.1], type: "city", name: "Avezzano" },
  { id: 1206, position: [13.29, -72.12], type: "mine", name: "Gold Mine" },
  { id: 1205, position: [13.3, -70.4], type: "city", name: "Sulmona" },
  { id: 1204, position: [14.35, -70.55], type: "mine", name: "Iron Mine" },
  { id: 1203, position: [15.05, -69.95], type: "city", name: "Chieti" },
  { id: 1202, position: [15.78, -70.65], type: "node", name: "" },
  { id: 1201, position: [16.6, -70.8], type: "city", name: "Silvi" },
  { id: 1200, position: [16.95, -71.35], type: "mine", name: "Stone Mine" },
  { id: 1199, position: [16.75, -72.5], type: "city", name: "Teramo" },
  { id: 1207, position: [16.1, -73.07], type: "mine", name: "Iron Mine" },
  { id: 1198, position: [15.8, -74.2], type: "city", name: "L'Aquila" },
  { id: 1197, position: [15.12, -74.98], type: "mine", name: "Stone Mine" },
  { id: 1196, position: [14.1, -75.2], type: "city", name: "Tagliacozzo" },
  { id: 1195, position: [13.34, -74.1], type: "mine", name: "Gold Mine" },
  { id: 1238, position: [16.22, -74.98], type: "mine", name: "Clay Mine" },
  { id: 1237, position: [16.96, -75.6], type: "node", name: "" },
  { id: 1236, position: [16.65, -76.66], type: "node", name: "" },
  { id: 1235, position: [16.98, -77.49], type: "node", name: "" },

];
