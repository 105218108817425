export const medals = [
  {
    name: "Medal of Honor",
    description:
      "Awarded to the Legionary who has shown the most dedication to the Legion and the Empire.",
    image: "1",
    awarded: false,
  },
  {
    name: "Medal of Honor",
    description:
      "Awarded to the Legionary who has shown the most dedication to the Legion and the Empire.",
    image: "2",
    awarded: false,
  },
  {
    name: "Medal of Honor",
    description:
      "Awarded to the Legionary who has shown the most dedication to the Legion and the Empire.",
    image: "3",
    awarded: false,
  },
  {
    name: "Medal of Honor",
    description:
      "Awarded to the Legionary who has shown the most dedication to the Legion and the Empire.",
    image: "4",
    awarded: false,
  },
  {
    name: "Medal of Honor",
    description:
      "Awarded to the Legionary who has shown the most dedication to the Legion and the Empire.",
    image: "5",
    awarded: false,
  },
  {
    name: "Medal of Honor",
    description:
      "Awarded to the Legionary who has shown the most dedication to the Legion and the Empire.",
    image: "6",
    awarded: false,
  },
  {
    name: "Medal of Honor",
    description:
      "Awarded to the Legionary who has shown the most dedication to the Legion and the Empire.",
    image: "7",
    awarded: false,
  },
  {
    name: "Medal of Honor",
    description:
      "Awarded to the Legionary who has shown the most dedication to the Legion and the Empire.",
    image: "8",
    awarded: false,
  },
  {
    name: "Medal of Honor",
    description:
      "Awarded to the Legionary who has shown the most dedication to the Legion and the Empire.",
    image: "9",
    awarded: false,
  },
  {
    name: "Medal of Honor",
    description:
      "Awarded to the Legionary who has shown the most dedication to the Legion and the Empire.",
    image: "10",
    awarded: false,
  },
  {
    name: "Medal of Honor",
    description:
      "Awarded to the Legionary who has shown the most dedication to the Legion and the Empire.",
    image: "11",
    awarded: false,
  },
  {
    name: "Medal of Honor",
    description:
      "Awarded to the Legionary who has shown the most dedication to the Legion and the Empire.",
    image: "12",
    awarded: false,
  },
  {
    name: "Medal of Honor",
    description:
      "Awarded to the Legionary who has shown the most dedication to the Legion and the Empire.",
    image: "13",
    awarded: false,
  },
];
