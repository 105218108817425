import { MedalsType } from "../Components/Legionary/Medals";
import { medals } from "./medals";

const awardMedals = (medals: any, awardedMedals: string[]) => {
  return medals.map((medal: any) => {
    if (awardedMedals.includes(medal.image)) {
      return { ...medal, awarded: true };
    }
    return { ...medal };
  });
};

export const legionaries = [
  {
    name: "Faraona",
    rank: "Legatus Legionis",
    legion: "OG",
    medals: awardMedals(medals, ["2", "7", "9"]),
  },
  {
    name: "Dudie",
    rank: "Tribunis Laticlavius",
    legion: "OG",
    medals: awardMedals(medals, []),
  },
  {
    name: "Mis_ok",
    rank: "Praetor",
    legion: "OG",
    medals: awardMedals(medals, []),
  },
  {
    name: "Leora",
    rank: "Praefectus",
    legion: "OG",
    medals: awardMedals(medals, []),
  },
  {
    name: "Ganfi",
    rank: "Praetor",
    legion: "First",
    medals: awardMedals(medals, []),
  },
  {
    name: "Tredmus",
    rank: "Praetor",
    legion: "Second",
    medals: awardMedals(medals, []),
  },
  {
    name: "Kash",
    rank: "Praefectus",
    legion: "Second",
    medals: awardMedals(medals, []),
  },
  {
    name: "Legolas_b",
    rank: "Praetor",
    legion: "Third",
    medals: awardMedals(medals, []),
  },
  {
    name: "Marto98",
    rank: "Legionarius",
    legion: "Second",
    medals: awardMedals(medals, []),
    reportsTo: "Kankrum",
  },
  {
    name: "VACANT",
    rank: "Praefectus",
    legion: "Third",
    medals: awardMedals(medals, []),
  },
  {
    name: "Ciara_.",
    rank: "Centurio",
    legion: "OG",
    medals: awardMedals(medals, []),
  },
  {
    name: "Morskasirena",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Atinapalada_ii",
  },
  {
    name: "Barborko",
    rank: "Centurio",
    legion: "OG",
    medals: awardMedals(medals, []),
  },
  {
    name: "Tanq_13",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Ciara_.",
  },
  {
    name: "Batemo",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Gimgi",
  },
  {
    name: "Freudiana",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Ciara_.",
  },
  {
    name: "Harpy",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Ciara_.",
  },
  {
    name: "Barborka",
    rank: "Centurio",
    legion: "OG",
    medals: awardMedals(medals, []),
  },
  {
    name: "Leire",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Atinapalada_ii",
  },
  {
    name: "Compeador",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Ciara_.",
  },
  {
    name: "Eve89",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Ciara_.",
  },
  {
    name: "Angelov76",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Barborko",
  },
  {
    name: "Lotor",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Barborka",
  },
  {
    name: "Vimes",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Barborko",
  },
  {
    name: "Kokicha",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Barborko",
  },
  {
    name: "Icarnifice",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Gimgi",
  },
  {
    name: "Direwolfdido",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Barborko",
  },
  {
    name: "Fars",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Atinapalada_ii",
  },
  {
    name: "Atinapalada_ii",
    rank: "Centurio",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Atinapalada_ii",
  },
  {
    name: "Ponko",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Ciara_.",
  },
  {
    name: "Lily_r",
    rank: "Legionarius",
    legion: "Second",
    medals: awardMedals(medals, []),
    reportsTo: "Gimgi",
  },
  {
    name: "Mircata",
    rank: "Legionarius",
    legion: "Third",
    medals: awardMedals(medals, []),
    reportsTo: "Scipioaf",
  },
  {
    name: "Kriviq",
    rank: "Legionarius",
    legion: "Third",
    medals: awardMedals(medals, []),
    reportsTo: "Scipioaf",
  },
  {
    name: "Krisinka",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Barborka",
  },
  {
    name: "Lepeasant",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Barborka",
  },
  {
    name: "Petur_purvi",
    rank: "Legionarius",
    legion: "Third",
    medals: awardMedals(medals, []),
    reportsTo: "Batman",
  },
  {
    name: "Drakan.",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Barborka",
  },
  {
    name: "Divaks",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Barborka",
  },
  {
    name: "Aleksandur",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Atinapalada_ii",
  },
  {
    name: "Steisi",
    rank: "Legionarius",
    legion: "Second",
    medals: awardMedals(medals, []),
    reportsTo: "Kankrum",
  },
  {
    name: "Varxovenyjas",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Barborko",
  },
  {
    name: "Senator_",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Barborko",
  },
  {
    name: "I_ivanov",
    rank: "Legionarius",
    legion: "OG",
    medals: awardMedals(medals, []),
    reportsTo: "Atinapalada_ii",
  },
  {
    name: "Gimgi",
    rank: "Centurio",
    legion: "First",
    medals: awardMedals(medals, []),
  },
  {
    name: "VACANT",
    rank: "Praefectus",
    legion: "First",
    medals: awardMedals(medals, []),
  },
  {
    name: "Growni",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Gimgi",
  },
  {
    name: "Evangelist.",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Gimgi",
  },
  {
    name: "Kolyo",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Gimgi",
  },
  {
    name: "Balinescu",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Gimgi",
  },
  {
    name: "Sracimir",
    rank: "Legionarius",
    legion: "Third",
    medals: awardMedals(medals, []),
    reportsTo: "Scipioaf",
  },
  {
    name: "Jorjo",
    rank: "Legionarius",
    legion: "Third",
    medals: awardMedals(medals, []),
    reportsTo: "Batman",
  },
  {
    name: "Kirilcho",
    rank: "Legionarius",
    legion: "Third",
    medals: awardMedals(medals, []),
    reportsTo: "Batman",
  },
  {
    name: "Dqkona",
    rank: "Legionarius",
    legion: "Third",
    medals: awardMedals(medals, []),
    reportsTo: "Scipioaf",
  },
  {
    name: "Gorrthegodbutcher",
    rank: "Legionarius",
    legion: "Third",
    medals: awardMedals(medals, []),
    reportsTo: "Batman",
  },
  {
    name: "Scipioaf",
    rank: "Centurio",
    legion: "Third",
    medals: awardMedals(medals, []),
  },
  {
    name: "Neversleep",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Gimgi",
  },
  {
    name: "Sahsa",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Arazortep",
  },
  {
    name: "Arazortep",
    rank: "Centurio",
    legion: "First",
    medals: awardMedals(medals, []),
  },
  {
    name: "Unspoken",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Prusiyski",
  },
  {
    name: "Falishmi",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Prusiyski",
  },
  {
    name: "Adriana",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Prusiyski",
  },
  {
    name: "Gron",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Arazortep",
  },
  {
    name: "Dreb4o",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Arazortep",
  },
  {
    name: "Crazyhacker",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Prusiyski",
  },
  {
    name: "Hadroy",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Prusiyski",
  },
  {
    name: "Alieryss",
    rank: "Legionarius",
    legion: "Third",
    medals: awardMedals(medals, []),
    reportsTo: "Scipioaf",
  },
  {
    name: "Batman",
    rank: "Centurio",
    legion: "Third",
    medals: awardMedals(medals, []),
  },

  {
    name: "Alienes",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Arazortep",
  },
  {
    name: "Gorskiq",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Arazortep",
  },
  {
    name: "Zzorov99",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Arazortep",
  },
  {
    name: "Prusiyski",
    rank: "Centurio",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Arazortep",
  },
  {
    name: "Krasiko",
    rank: "Centurio",
    legion: "Second",
    medals: awardMedals(medals, []),
  },
  {
    name: "Maximko",
    rank: "Centurio",
    legion: "Second",
    medals: awardMedals(medals, []),
  },
  {
    name: "Wieczny",
    rank: "Legionarius",
    legion: "Second",
    medals: awardMedals(medals, []),
    reportsTo: "Kankrum",
  },
  {
    name: "F3lizz",
    rank: "Legionarius",
    legion: "Second",
    medals: awardMedals(medals, []),
    reportsTo: "Krasiko",
  },
  {
    name: "Jenaoh",
    rank: "Legionarius",
    legion: "Second",
    medals: awardMedals(medals, []),
    reportsTo: "Krasiko",
  },

  {
    name: "Shizola",
    rank: "Legionarius",
    legion: "Second",
    medals: awardMedals(medals, []),
    reportsTo: "Krasiko",
  },
  {
    name: "Kankrum",
    rank: "Centurio",
    legion: "Second",
    medals: awardMedals(medals, []),
    reportsTo: "Krasiko",
  },
  {
    name: "Temptation",
    rank: "Legionarius",
    legion: "Second",
    medals: awardMedals(medals, []),
    reportsTo: "Kankrum",
  },
  {
    name: "Anatema",
    rank: "Legionarius",
    legion: "Second",
    medals: awardMedals(medals, []),
    reportsTo: "Krasiko",
  },
  {
    name: "Kokor4o",
    rank: "Legionarius",
    legion: "Second",
    medals: awardMedals(medals, []),
    reportsTo: "Maximko",
  },
  {
    name: "Botev",
    rank: "Legionarius",
    legion: "Second",
    medals: awardMedals(medals, []),
    reportsTo: "Kankrum",
  },
  {
    name: "Katyaa",
    rank: "Legionarius",
    legion: "Second",
    medals: awardMedals(medals, []),
    reportsTo: "Maximko",
  },
  {
    name: "Chakalat",
    rank: "Legionarius",
    legion: "Second",
    medals: awardMedals(medals, []),
    reportsTo: "Maximko",
  },
  {
    name: "Galabdonev",
    rank: "Legionarius",
    legion: "Second",
    medals: awardMedals(medals, []),
    reportsTo: "Gimgi",
  },
  {
    name: "Tigaragde",
    rank: "Legionarius",
    legion: "Second",
    medals: awardMedals(medals, []),
    reportsTo: "Maximko",
  },
  {
    name: "Bikini",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Prusiyski",
  },
  {
    name: "Gabobg",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Arazortep",
  },
  {
    name: "Mayora",
    rank: "Legionarius",
    legion: "First",
    medals: awardMedals(medals, []),
    reportsTo: "Prusiyski",
  },
  {
    name: "Veneno",
    rank: "Legionarius",
    legion: "Second",
    medals: awardMedals(medals, []),
    reportsTo: "Krasiko",
  },
  {
    name: "Panayot",
    rank: "Legionarius",
    legion: "Second",
    medals: awardMedals(medals, []),
    reportsTo: "Maximko",
  },
];

export const legionariesCount = legionaries.length;
