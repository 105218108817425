import { useEffect, useState } from "react";
import CloseButton from "../Buttons/CloseButton";
import classes from "./HistoryBox.module.scss";

const HistoryBox = ({ data, onClose, selectedDate, setSelectedDate }) => {
  const [years, setYears] = useState([]);
  const [year, setYear] = useState(selectedDate ? new Date(selectedDate).getFullYear() : null);
  const [date, setDate] = useState(selectedDate ? selectedDate : null);

  useEffect(() => {
    if (Array.isArray(data)) {
      // Extract unique years
      const uniqueYears = Array.from(
        new Set(data.map((item) => new Date(item.date).getFullYear()))
      );
      setYears(uniqueYears.sort((a, b) => b - a)); // Sort in descending order
    }
  }, [data]);

  const handleYearChange = (selectedYear) => {
    setYear(selectedYear);
    setDate("");
  };

  const handleDateChange = (selectedDate) => {
    setDate(selectedDate);
    setSelectedDate(selectedDate);
  };

  const generateDateOptions = () => {
    if (!year) return [];

    const filteredDates = data
      .filter((item) => new Date(item.date).getFullYear() === parseInt(year, 10))
      .map((item) => ({
        date: item.date,
        formattedDate: new Date(item.date).toLocaleDateString("en-US", {
          month: "numeric",
          day: "numeric",
        }),
      }));

    return filteredDates.map((item) => (
      <option key={item.date} value={item.date}>
        {item.formattedDate}
      </option>
    ));
  };

  const canGoToNextDate = () => {
    const dateOptions = generateDateOptions().map((date) => date.props.value);
    const currentIndex = dateOptions.indexOf(date);

    return currentIndex < dateOptions.length - 1;
  };

  const goToNextDate = () => {
    if (canGoToNextDate()) {
      const dateOptions = generateDateOptions().map((date) => date.props.value);
      const currentIndex = dateOptions.indexOf(date);
      const nextDate = dateOptions[currentIndex + 1];
      setDate(nextDate);
      setSelectedDate(nextDate);
    }
  };

  return (
    <div className={classes.form}>
      <CloseButton onClose={onClose} />
      <h5>History Viewer</h5>
      <div className={classes.group}>
        <select value={year} onChange={(e) => handleYearChange(e.target.value)}>
          <option value="" hidden>Select Year</option>
          {years.map((y) => (
            <option key={y} value={y}>{y}</option>
          ))}
        </select>
        <select value={date} onChange={(e) => handleDateChange(e.target.value)} disabled={year ? false : true}>
          <option value="" hidden>
            Select MM/DD
          </option>
          {generateDateOptions()}
        </select>
        <button
          className={classes.btn}
          onClick={goToNextDate}
          disabled={!canGoToNextDate()}
        >
          ➔
        </button>
      </div>
    </div>
  );
};

export default HistoryBox;
