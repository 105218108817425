import classes from "./History.module.scss";
import { icons } from "../../Assets/Icons/Icons";
import { Event } from "../../Components/Event/Event";

const today = new Date();
const birth = new Date("2013-04-05");
let age = today.getFullYear() - birth.getFullYear();
if (
  today.getMonth() - birth.getMonth() < 0 ||
  today.getMonth() - birth.getMonth() === 0
)
  age--;

export const History = () => {
  return (
    <>
      <div className={classes.background}></div>
      <div className={`${classes.page} ${classes.history}`}>
        <h2 className={classes.heading}>
          Историята <span>{age} години Боен Легион</span>
        </h2>
        <div className={classes.body}>
          <div className={classes.timeline}>
            <div className={classes.start}>
              <icons.BiBook />
            </div>
            <ul>
              <li>
                <Event
                  name={"Предистория"}
                  date={"04.04.1461 (2013)"}
                  description={
                    "След зов за помощ от Knights of Wallachia отпратен към лидера на Нова Българска Армия - Faraonqbg, той подема усилията да събере военна подкрепа от клановете в България на, която се озовават - Хайдушки Дружини, Нов Път, които подготвят поход към Влахия, където успяват да пленят 9 бойни знамена на O.N.E и да превземат столицата Търговище."
                  }
                />
              </li>
              <li>
                <Event
                  name={"Създаване на Боен Легион"}
                  date={"05.04.1461 (2013)"}
                  description={
                    "На 05.04.1461 е създаден Боен Легион, като алианс(съюз) съдържащ в себе си няколко военни и политически организации. Алиансът представлява сбор от организации, заинтересовани от бъдещето на Царство България и Княжество Влахия, които са Хайдушки Дружини, Нова Българска Армия, Нов Път, Knights of Wallachia. Целта на БЛ е да коригира грешката в досегашните правителства, чиято политика е пълен неутралитет и малодушие. "
                  }
                />
              </li>
              <li>
                <Event
                  name={"Мир между Сърбия, Босна и Албания благодарение на БЛ"}
                  date={"07.05.1461 (2013)"}
                  description={
                    "Благодарение на медиаторството на Боен Легион е сключен мирен договор между воюващите Сърбия, Босна и Албания. Договорът за примирие е публикуван в балканският форум от легата на Боен Легион - Faraonqbg."
                  }
                />
              </li>
              <li>
                <Event
                  name={"Създадена е първата листа на Боен Легион"}
                  date={"12.04.1461 (2013)"}
                  description={
                    "Създадена е първата листа на Боен Легион с участници от формациите - Хайдушки Дружини, Нов Път, Нова Българска Армия, която печели изборите с 41%."
                  }
                />
              </li>
              <li>
                <Event
                  name={"Разпадане на Боен Легион"}
                  date={"02.06.1461 (2013)"}
                  description={
                    "Лидерът на Нова Българска Армия - Faraonqbg, подава оставка от поста Легат и изтегля организацията си от алианса, като цитира нежеланието на лидерите на останалите организации да поемат своя дял от труда. Организацията просъществува още известно време, като не постига никакви успехи в политиката или войната, след което се разпуска."
                  }
                />
              </li>
              <li>
                <Event
                  name={"Ново начало"}
                  date={"08.03.1469 (2021)"}
                  description={
                    "На 8ми март 1469та година Боен Легион е възроден от Faraonqbg, като военна организация целяща създаването на професионална армия, способна да се справи с всяка заплаха над Царство България."
                  }
                />
              </li>
              <li>
                <Event
                  name={"Сваляне на съвета"}
                  date={"15.03.1469 (2021)"}
                  description={
                    "Съвета на Царство България бива свален от Боен Легион, заради незачитане на изборният резултат и наглото поведение на опонентите им. Това води до  започване гражданска война между него и Carthage Sacred Band, Хайдушки Дружики, Order of The Phoenix (Медичи), Lords of Chaos, The Followship, Ronin и други. Гражданската война завършва с админска намеса, като има много изтрити от двете страни и няма явен победител."
                  }
                />
              </li>
              <li>
                <Event
                  name={"Връщане на Видин"}
                  date={"19.06.1469 (2021)"}
                  description={
                    "Армията на боен легион връща Видин към пределите на Царство България, който бива отцепен месец по рано от армиите на O.N.E след като никоя друга организация не дръзва да напусне Търново."
                  }
                />
              </li>
              <li>
                <Event
                  name={"Избори за Цар"}
                  date={"13.07.1470 (2022)"}
                  description={
                    "Faraonqbg бива избран за първия Български Цар още на първи тур с 60.53%, като побеждава Bockovice_de_medici (Paha), която е подкрепена от КСБ, Lords of Chaos, ХД, The Fellowship, Ордена на седемте никси и други."
                  }
                />
              </li>
              <li>
                <Event
                  name={"Поход в Османската Империя"}
                  date={"13.07.1470 (2022)"}
                  description={
                    "След избирането си за Цар Faraonqbg обявява мобилизация на която се озовават 4 български армии, 3 от които на Боен Легион същоящи се от 6 взвода и една на КСБ състояща се от един взвод. Похода има за цел да подпомогне Османската Империя в борбата им за освобождението на одринския санджак."
                  }
                />
              </li>
            </ul>
          </div>
          <footer className={classes.quote}>
            <p>
              "Или умираш като герой, или живееш достатъчно дълго, за да се
              превърнеш в злодей"
            </p>
          </footer>
        </div>
      </div>
    </>
  );
};
